<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card class="primary--border" outlined>
                    <v-card-title v-if="title" class="title">
                        <alert-message :title="title + ' ('+ assignmentDate+')'" :message="description"></alert-message>
                    </v-card-title>


                    <v-data-table
                            :headers="headers"
                            :items="form.items.data"
                            :options.sync="pagination"

                            hide-default-footer
                            :loading="form.loading"
                            :footer-props="{
                              showFirstLastPage: true,
                              firstIcon: 'mdi-arrow-collapse-left',
                              lastIcon: 'mdi-arrow-collapse-right',
                              prevIcon: 'mdi-minus',
                              nextIcon: 'mdi-plus'}">
                        <template v-slot:item="{item,index}">
                            <tr>
                                <td>{{ item.student.roll }}</td>
                                <td class="text-xs-left">{{ item.student ? (item.student.name ?
                                    item.student.name:'-'):'-' }}
                                </td>
                                <td class="text-xs-left">
                                    <v-chip small label color="primary" text-color="white">
                                        {{ item.assignment.assignment_date }}
                                    </v-chip> &nbsp;
                                    <v-chip small label color="orange" text-color="white">
                                        {{ item.assignment.submission_date }}
                                    </v-chip>
                                    <!--{{ item.assignment.submission_date }}-->
                                </td>
                                <td>
                                <span v-if="item.document && item.document.length > 0">
                                        <span v-for="(x ,i) in item.document"
                                              :key="i">
                                            <a target="_blank" :href="x.docs"
                                               style="text-decoration: none">
                                                <v-tooltip bottom>
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon v-on="on">attach_file</v-icon>
                                                    </template>
                                                    <span>Click to download doc.</span>
                                                </v-tooltip>
                                            </a>
                                            </span>
                                    </span>
                                    <span v-else>N/A</span>
                                </td>
                                <td class="text-xs-left">{{ item.description }}</td>

                                <td class="text-xs-left">
                                    <v-radio-group

                                            v-model="item.status" row
                                            @change="setRemarks(item,index)"
                                            :hide-details="true" class="pt-0">
                                        <v-radio label="Present" :value="2" color="success">
                                            <template v-slot:label>
                                                <div>
                                                    <strong class="success--text">
                                                        <small>Complete</small>
                                                    </strong>
                                                </div>
                                            </template>
                                        </v-radio>
                                        <v-radio label="Late" :value="1" color="warning">
                                            <template v-slot:label>
                                                <div><strong class="warning--text">
                                                    <small>Partial Complete</small>
                                                </strong></div>
                                            </template>
                                        </v-radio>

                                        <v-radio label="Leave" :value="0" color="error">
                                            <template v-slot:label>
                                                <div>
                                                    <strong class="error--text">
                                                        <small>Not Done</small>
                                                    </strong>
                                                </div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                </td>
                            </tr>
                        </template>
                        <v-alert slot="no-results" :value="true" color="error" icon="warning">
                            Your search for "{{ search }}" found no results.
                        </v-alert>
                    </v-data-table>

                    <v-card-actions>
                        <!--<v-spacer/>-->
                        <!--<v-pagination v-model="pagination.page" :length="form.items.meta.last_page"></v-pagination>-->
                        <!--<v-spacer/>-->
                    </v-card-actions>
                </v-card>
            </v-flex>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                lazy-validation>
                            <v-layout row wrap>
                                <v-flex xs12 sm12>
                                    <br>
                                    <v-textarea
                                            outlined
                                            name="description"
                                            label="Description (optional)"
                                            v-model="form.description"
                                            required
                                            :error-messages="form.errors.get('description')"
                                    ></v-textarea>
                                </v-flex>

                                <v-flex xs12 class="text-xs-center text-sm-center text-md-center text-lg-center">
                                    <img :src="imageUrl" height="150" v-if="imageUrl"/>
                                    <v-text-field outlined label="Select Image (optional)" @click='pickFile'
                                                  v-model='imageName'
                                                  prepend-icon='attach_file'></v-text-field>
                                    <input
                                            type="file"
                                            style="display: none"
                                            ref="image"
                                            accept="image/*"
                                            @change="onFilePicked"
                                    >
                                </v-flex>
                            </v-layout>
                            <small>*indicates required field</small>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outlined @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outlined @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'
    import Mixins from '@/library/Mixins';

    export default {
        mixins: [Mixins],
        data: () => ({
            form: new Form({
                description: '',
                document: '',
                assignment_id: '',
                student_id: '',
                status: 0,

            }, '/api/assignment-student'),
            search: null,
            searchStudent: '',
            pagination: {
                rowsPerPage: 200,
                itemsPerPage: 200,
            },
            headers: [
                {text: 'R No.', align: 'left', value: 'id', width: 80, sortable: false},
                {text: 'Student', align: 'left', value: 'name', sortable: false},
                {
                    text: 'Assigned / Submission Date',
                    align: 'left',
                    value: 'submission_date',
                    sortable: false,
                    width: 250
                },
                {text: 'Docs', align: 'left', value: 'student_docs', width: 50, sortable: false},
                {text: 'Description', align: 'left', value: 'status', sortable: false, width: 400},
                {text: 'Status', align: 'centre', value: 'status', width: 400, sortable: false},
                // {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            imageName: '',
            imageUrl: '',
            imageFile: '',
            dialog: false,
            title: '',
            assignmentDate: '',
            description: ''
        }),

        computed: {
            ...mapState(['batch'])
        },

        mounted() {
            this.get();
            this.getGrades();
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'pagination.page': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&assignmentId=' + this.$route.params.assignmentId;
            },

            get(params) {
                let extraPara = '&assignmentId=' + this.$route.params.assignmentId;
                // let query = [null, undefined].includes(params) ? this.queryString() : params;
                let query = this.queryString(extraPara);
                this.form.get(null, query).then(({data}) => {
                    this.title = data.data[0].assignment.title;
                    this.assignmentDate = data.data[0].assignment.assignment_date;
                    this.description = data.data[0].assignment.description;
                    this.pagination.totalItems = data.meta.total

                })
            },

            save() {
                this.form.document = this.imageFile;
                // this.form.assignment_id
                this.form.store().then(response => {
                    this.imageFile = '';
                    this.imageUrl = '';
                    this.imageName = '';
                });
            },
            getGrades() {
                this.$rest.get('/api/grades?rowsPerPage=25&sortBy=rank&descending=false').then(({data}) => {
                    this.grades = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            getSubjects() {
                this.$rest.get('/api/subject?grade=' + this.grade + '&batch=' + this.batch.id).then(({data}) => {
                    this.subjects = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },

            getSections() {
                this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.grade).then(({data}) => {
                    this.sections = data.data.map(item => {
                        return {value: item.id, text: item.name}
                    });
                })
            },
            pickFile() {
                this.$refs.image.click()
            },

            onFilePicked(e) {
                const files = e.target.files;
                if (files[0] !== undefined) {
                    this.imageName = files[0].name;
                    if (this.imageName.lastIndexOf('.') <= 0) {
                        return
                    }
                    const fr = new FileReader();
                    fr.readAsDataURL(files[0]);
                    fr.addEventListener('load', () => {
                        this.imageUrl = fr.result;
                        this.imageFile = files[0] // this is an image file that can be sent to server...
                    })
                } else {
                    this.imageName = '';
                    this.imageFile = '';
                    this.imageUrl = '';
                }
            },
            editData(item) {
                if (item.status === null || item.status === '') {
                    item.status = '';
                }
            },
            setRemarks(item) {
                this.form.edit(item);
                this.form.id = item.id;

            }

        }
    }
</script>
<style lang="scss" scoped></style>